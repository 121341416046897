// get data of form taht has name="quote" having fields first_name, last_name, email, phone, subject, message and send it to https://submit-form.com/KBAX2TBO3

try {
  const form = document.querySelector('form[name="quote"]');
  const submit_button = document.querySelector("#quote-submit-js");

  submit_button.addEventListener("click", (e) => {
    e.preventDefault();
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    const url = "https://submit-form.com/KBAX2TBO3";
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          alert("Thank you for your message. We will get back to you soon.");
          form.reset();
        } else {
          alert("Thank you for your message. We will get back to you soon.");
        }
        form.reset();
      })
      .catch((err) => {
        form.reset();
        alert("Thank you for your message. We will get back to you soon.");
      });
  });
} catch (e) {}

// get data of form taht has name="quote" having fields first_name, last_name, email, phone, subject, message and send it to https://submit-form.com/KBAX2TBO3

try {
  const form = document.querySelector('form[name="contact"]');
  const submit_button = document.querySelector("#contact-submit-js");

  submit_button.addEventListener("click", (e) => {
    e.preventDefault();
    const formData = new FormData(form);
    const data = Object.fromEntries(formData);
    const url = "https://submit-form.com/KBAX2TBO3";
    const options = {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(url, options)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          alert("Thank you for your message. We will get back to you soon.");
          form.reset();
        } else {
          alert("Thank you for your message. We will get back to you soon.");
        }
        // reset form
        form.reset();
      })
      .catch((err) => {
        form.reset();
        alert("Thank you for your message. We will get back to you soon.");
      });
  });
} catch (e) {}
